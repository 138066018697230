import FilterFooter from '@/Search/components/FilterFooter.vue';
import FilterOption from '@/Search/components/FilterOption.vue';
import FilterPopover from '@/Search/components/FilterPopover.vue';
import FilterModal from '@/Search/components/FilterModal.vue';
import EventBus from '@/shared/services/eventBus';
import { defineComponent, ref } from '@vue/composition-api';
import { SortMethods } from '@/Search/search.const';
export default defineComponent({
    components: {
        FilterFooter,
        FilterOption,
        FilterModal,
        FilterPopover
    },
    props: {
        isOnSearchPage: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const sortingValues = [
            {
                title: 'sort_by_price',
                icon: 'fa-coins',
                sortBy: [
                    { name: 'low_to_high', label: 'sortByPriceASC' },
                    { name: 'high_to_low', label: 'sortByPriceDESC' }
                ]
            },
            {
                title: 'sort_by_rating',
                icon: 'fa-star',
                sortBy: [
                    { name: 'low_to_high', label: 'sortByRatingASC' },
                    { name: 'high_to_low', label: 'sortByRatingDESC' }
                ]
            },
            {
                title: 'sort_by_size',
                icon: 'fa-maximize',
                sortBy: [
                    { name: 'small_to_large', label: 'sortBySizeASC' },
                    { name: 'large_to_small', label: 'sortBySizeDESC' }
                ]
            }
        ];
        let selectedSortingValue = ref(null);
        const sortByValue = (sortMethod) => {
            if (selectedSortingValue.value === sortMethod) {
                selectedSortingValue.value = null;
            }
            else {
                selectedSortingValue.value = sortMethod;
            }
        };
        const handleSubmitSorting = () => {
            selectedSortingValue.value
                ? toggleSortOrder(selectedSortingValue.value)
                : toggleSortOrder('resetSort');
        };
        const executeSortByPrice = (a, b, sortMethod) => {
            let sortingResult = a.halfDayPrice - b.halfDayPrice; // Default ASC order
            if (sortMethod == SortMethods.SORT_BY_PRICE_DESC) {
                sortingResult = b.halfDayPrice - a.halfDayPrice; // DESC order
            }
            return sortingResult;
        };
        const executeSortByRating = (a, b, sortMethod) => {
            let sortingResult = a.organisationRating - b.organisationRating; // Default ASC order
            if (sortMethod == SortMethods.SORT_BY_RATING_DESC) {
                sortingResult = b.organisationRating - a.organisationRating; // DESC ASC order
            }
            return sortingResult;
        };
        const executeSortBySize = (a, b, sortMethod) => {
            let sortingResult = a.spaceSize - b.spaceSize; // Default ASC order
            if (sortMethod === SortMethods.SORT_BY_SIZE_DESC) {
                sortingResult = b.spaceSize - a.spaceSize; // DESC order
            }
            return sortingResult;
        };
        const toggleSortOrder = (sortMethod) => {
            const sortingMap = {
                sortByPriceASC: executeSortByPrice,
                sortByPriceDESC: executeSortByPrice,
                sortByRatingASC: executeSortByRating,
                sortByRatingDESC: executeSortByRating,
                sortBySizeASC: executeSortBySize,
                sortBySizeDESC: executeSortBySize
            };
            const eventCallback = sortingMap[sortMethod] || null;
            EventBus.$emit(sortMethod, eventCallback);
        };
        EventBus.$on('filtersResetButtonClicked', () => {
            // Listen when the general `Reset` button in the filters bar is clicked
            reset();
        });
        const reset = () => {
            selectedSortingValue.value = null;
            EventBus.$emit('resetSort');
            return undefined;
        };
        return {
            sortingValues,
            selectedSortingValue,
            handleSubmitSorting,
            reset,
            sortByValue,
            toggleSortOrder
        };
    }
});
