import SpaceCard from '@/Search/components/SpaceCard.vue';
import { defineComponent, ref, computed, onMounted, watch } from '@vue/composition-api';
import { SortMethods } from '@/Search/search.const';
import EventBus from '@/shared/services/eventBus';
import SearchSorting from '@/Search/components/SearchSorting.vue';
import ShowOnMapModal from '@/shared/components/ShowOnMapModal.vue';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import qs from 'query-string';
import PagePagination from '@/components/PagePagination.vue';
export default defineComponent({
    components: {
        PagePagination,
        SearchSorting,
        SpaceCard,
        ShowOnMapModal
    },
    props: {
        spaces: {
            type: Array,
            default: () => []
        },
        venues: {
            type: Array,
            default: () => []
        },
        isOnCityPage: {
            type: Boolean,
            default: false
        },
        venueName: {
            type: String,
            default: ''
        },
        cityName: {
            type: String,
            default: ''
        },
        cityCoordinates: {
            type: Object,
            default: () => { }
        },
        tag: {
            type: String,
            default: ''
        }
    },
    setup(props, context) {
        const { root } = context;
        const initialShowAmount = ref(10);
        const sortingMethod = ref(SortMethods.RESET);
        const sortingCallback = ref(null);
        const isMapModalVisible = ref(false);
        const page = ref(null);
        const maxPage = computed(() => Math.ceil(props.spaces?.length / initialShowAmount.value));
        const spacesToShow = computed(() => {
            const result = props.spaces?.filter(Boolean).map(space => space) || [];
            if (!props.isOnCityPage) {
                return result;
            }
            if (sortingCallback.value) {
                result.sort((a, b) => sortingCallback.value(a, b, sortingMethod.value));
            }
            return result.slice(initialShowAmount.value * page.value - initialShowAmount.value, initialShowAmount.value * page.value);
        });
        const toggleShowOnMap = () => {
            isMapModalVisible.value = !isMapModalVisible.value;
        };
        watch(() => props.spaces, () => {
            if (props.isOnCityPage && props.spaces) {
                const currentQueryParams = qs.parse(location.search, {
                    arrayFormat: 'bracket'
                });
                page.value =
                    currentQueryParams.page &&
                        Number(currentQueryParams.page) > 0 &&
                        Number(currentQueryParams.page) <= maxPage.value
                        ? Number(currentQueryParams.page)
                        : 1;
                history.replaceState({}, null, page.value === 1 ? '' : `?page=${page.value}`);
            }
        }, { immediate: true });
        onMounted(() => {
            Object.values(SortMethods).forEach(sortMethod => {
                EventBus.$on(sortMethod, cb => {
                    sortingMethod.value = sortMethod;
                    sortingCallback.value = cb;
                });
            });
        });
        const searchQuery = computed(() => {
            const query = {
                lat: props.cityCoordinates.latitude,
                lng: props.cityCoordinates.longitude,
                tag: props.tag || undefined,
                search: props.cityName
            };
            return query;
        });
        const handlePaginationButtonClick = () => {
            if (props.isOnCityPage) {
                history.replaceState({}, null, page.value === 1 ? window.location.pathname : `?page=${page.value}`);
                root.$scrollTo('#spaces', 500, {
                    offset: -100
                });
            }
        };
        return {
            initialShowAmount,
            isMapModalVisible,
            maxPage,
            page,
            searchQuery,
            spacesToShow,
            handlePaginationButtonClick,
            toggleShowOnMap,
            useGetLocalizedPath
        };
    }
});
