import { defineComponent, ref, watch } from '@vue/composition-api';
export default defineComponent({
    props: {
        hasApplyButton: {
            type: Boolean,
            default: true
        },
        hasResetButton: {
            type: Boolean,
            default: true
        },
        scrollStatus: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const shadowMode = ref(props.scrollStatus);
        watch(() => props.scrollStatus, (curr, prev) => {
            if (curr !== prev) {
                shadowMode.value = curr;
            }
        }, { immediate: true });
        return { shadowMode };
    }
});
