import { defineComponent } from '@vue/composition-api';
import { Portal } from 'portal-vue';
export default defineComponent({
    name: 'FilterModal',
    components: { Portal },
    props: {
        isShort: {
            type: Boolean,
            default: false
        },
        portal: {
            type: String,
            default: ''
        }
    }
});
